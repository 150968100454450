import React from 'react'
import { connect } from 'react-redux'
import { Button, Box, Heading, Text } from 'grommet'
import AppLayout from '../components/app-layout'
import Popup from 'reactjs-popup'
import { format } from 'date-fns'
import { cancelSubscription } from '../actions/subscriptions'

const SKUS = {
  gini_smart_list_12: 'Smart List + Stories (12 Months)',
  gini_smart_list_6: 'Smart List + Stories (6 Months)',
  gini_smart_list_3: 'Smart List + Stories (3 Months)',
  gini_smart_list_1: 'Smart List + Stories (1 Month)',
  gini_stories: 'Stories (12 Months)'
}

const mapStateToProps = state => ({
  ...state.user,
  subscriptions: state.subscription.activeSubs,
  cancellationStatus: state.subscription.cancellationStatus
})

const mapDispatchToProps = dispatch => ({
  onCancel: (uid, sku) => dispatch(cancelSubscription(uid, sku))
})

class Subscriptions extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  getModalContent = (sku, origin, expiryDate) => {
    if (origin === 'shopify') {
      return (
        <Text style={{ marginTop: 20 }}>
          Are you sure you want to cancel your subscription for {SKUS[sku]}?
          Your subscription will end on {format(expiryDate, 'MMMM DD, YYYY')}.
        </Text>
      )
    }
    if (origin === 'ios') {
      return (
        <Text style={{ marginTop: 20 }}>
          Looks like this subscription is purchased through your iOS device,
          please manage your subscription on the iTunes Store.
        </Text>
      )
    }
    if (origin === 'android') {
      return (
        <Text style={{ marginTop: 20 }}>
          Looks like this subscription is purchased through your Android device,
          please manage your subscription Google Play Store.
        </Text>
      )
    }
  }

  getStatus = (cancellationStatus, sku, subscription) => {
    if (!cancellationStatus) {
      if (subscription.expiryDate < Date.now()) {
        return 'expired'
      }
      return 'active'
    }
    const cancellationSku = cancellationStatus[sku]
    if (cancellationSku) {
      return 'cancelled'
    }
    if (cancellationSku === false) {
      return 'Cancelation Requested'
    }
    return 'active'
  }

  getCancellation = (uid, cancellationStatus, subscription) => {
    if (cancellationStatus) {
      if (
        cancellationStatus[subscription.sku] === false ||
        cancellationStatus[subscription.sku] === true
      ) {
        return <Button label="Cancel" color="accent-2" primary />
      }
      return this.cancel(
        uid,
        subscription.sku,
        subscription.origin,
        subscription.expiryDate
      )
    }
    return this.cancel(
      uid,
      subscription.sku,
      subscription.origin,
      subscription.expiryDate
    )
  }

  cancel = (uid, sku, origin, expiryDate) => (
    <Popup trigger={<Button label="Cancel" color="accent-1" primary />} modal>
      {
        close => (
          <Box style={{ margin: 20 }}>
            <Text weight={'bold'}>Cancel {SKUS[sku]} </Text>
            {
              this.getModalContent(sku, origin, expiryDate)
            }
            {
              origin !== 'shopify' ? (
                <Button
                  label="Okay"
                  color="accent-1"
                  primary
                  style={{ width: 75, alignSelf: 'center', marginTop: 20 }}
                  onClick={() => {
                    close()
                  }}
                />
              ) : (
                <Box
                  style={{
                    marginTop: 20,
                    flexDirection: 'row',
                    justifyContent: 'space-evenly'
                  }}
                >
                  <Button
                    label="Yes"
                    color="accent-1"
                    primary
                    style={{ width: 75 }}
                    onClick={() => {
                      this.props.onCancel(uid, sku)
                      close()
                    }}
                  />
                  <Button
                    label="No"
                    color="accent-1"
                    primary
                    style={{ width: 75 }}
                    onClick={() => {
                      close()
                    }}
                  />
                </Box>
              )
            }
          </Box>
        )
      }
    </Popup>
  )

  render() {
    const { subscriptions, uid, cancellationStatus, ...userProps } = this.props
    return (
      <AppLayout>
        <Box
          pad={{ horizontal: 'large', vertical: 'large' }}
          margin={{ horizontal: 'small' }}
        >
          <Heading level={2} color="accent-2">
            Your Subscriptions
          </Heading>
          {
            subscriptions
            ? subscriptions.map(subscription => {
                return subscription.isSub ? (
                  <Box
                    key={subscription.sku}
                    style={{
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      marginBottom: 10,
                      marginLeft: 10,
                      marginRight: 10,
                      alignItems: 'center'
                    }}
                  >
                    <Text style={{ width: 500 }}>{SKUS[subscription.sku]}</Text>
                    <Text style={{ width: 200 }}>
                      {
                        subscription.expiryDate
                        ? format(subscription.expiryDate, 'MMMM DD, YYYY')
                        : null
                      }
                    </Text>
                    <Text style={{ width: 200 }}>
                      {
                        this.getStatus(
                          cancellationStatus,
                          subscription.sku,
                          SKUS[subscription.sku]
                        )
                      }
                    </Text>
                    {
                      this.getCancellation(uid, cancellationStatus, subscription)
                    }
                  </Box>
                ) : null
              })
            : null
          }
        </Box>
      </AppLayout>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Subscriptions)
